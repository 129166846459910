
$ = jQuery

window.ulist_remove = ->
  $ulist = $('#ulist')
  do ( $ulist ) ->
    $ulist.removeClass('showing')
    setTimeout ->
      $ulist.remove()
    , 1001

# tbanner(text, { font-size: font_size, top: top, left: left, width: width: height: height, color: color, text-align: 'center' } )
#
# params can be used to over-ride default css

window.ulist = (list = [ 'one', 'two', 'three' ], css_params = {}) ->

  $ulist = $('#ulist')

  if ! $ulist[0]
    $ulist = $("<div id='ulist' class='ulist'></div>")
    $('body').append $ulist
    #setTimeout ->
    #  $ulist.addClass('showing')
    #, 1001

  if list?
    new_content = ""
    for row in list
      new_content += "<div class='ulist-row'>#{row}</div>"

    $ulist.html(new_content)

  if css_params?
    $ulist.css css_params

  return $ulist

window.ulist_show = ->
  $('#ulist').removeClass('d-none')

window.ulist_hide = (ms = 1002) ->
    setTimeout ->
      $('#ulist').addClass('d-none')
    , ms

window.ulist_add_class = (klass = 'showing') ->
  $('div.ulist').addClass(klass)

window.ulist_remove_class_all = ( klass = 'showing') ->
  $('div.ulist').removeClass(klass)

window.ulist_add_class_all = (klass = 'showing') ->
  $('div.ulist-row').addClass(klass)

window.ulist_remove_class_all = ( klass = 'showing') ->
  $('div.ulist-row').removeClass(klass)

window.ulist_add_class_index = (index = 0, klass = 'showing') ->
  $('div.ulist-row').eq(index).addClass(klass)

window.ulist_remove_class_index = (index = 0, klass = 'showing') ->
  $('div.ulist-row').eq(index).removeClass(klass)

