/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'external' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('../fun/sarrow.js')
require('../fun/sbanner.js')
require('../fun/tbanner.js')
require('../fun/ulist.js')
require('../fun/stitle.js')
require('../fun/szoom.js')
